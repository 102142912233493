<div [ngClass]="modalClasses">
  <div class="relative w-auto my-6 mx-6 max-w-6xl">
    <div class="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <div class="pt-6 pb-8 text-center w-[calc(100vw-32px)]" [class]="showOutline ? '' : 'p-4'">
        @if (type !== CONFIRM_DIALOG_TYPE.DEFAULT || icon) {
          <div class="flex justify-center mb-2">
            <app-icon [name]="iconName()" [size]="52"></app-icon>
          </div>
        }
        <div class="text-lg font-bold mb-2">{{ title }}</div>
        @if (showOutline) {
          <div class="border-b border-solid border-blueGray-200 rounded-t"></div>
          <div class="text-base mb-6 whitespace-break-spaces pt-6 px-4">{{ description }}</div>
          <div class="border-b border-solid border-blueGray-200 rounded-t"></div>
          <div class="pt-6 px-4">
            <app-button
              [label]="buttonText"
              buttonType="primary"
              buttonSize="l-full"
              class="my-auto"
              (clickButton)="close()"
            ></app-button>
          </div>
        } @else {
          <div class="text-base mb-6 whitespace-break-spaces">{{ description }}</div>
          <app-button
            [label]="buttonText"
            buttonType="primary"
            buttonSize="l-full"
            class="my-auto"
            (clickButton)="close()"
          ></app-button>
        }
      </div>
    </div>
  </div>
</div>
<div class="opacity-70 fixed inset-0 z-40 bg-black"></div>
