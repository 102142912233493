import {
  ConfirmDialogType,
  ConfirmDialogComponent,
} from '@shared/components/v1/confirm-dialog/confirm-dialog.component'
import { Injectable, inject } from '@angular/core'
import { ComponentPortal } from '@angular/cdk/portal'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'

export interface ModalInfoOptions {
  showOutline?: boolean
  type?: ConfirmDialogType
  title?: string
  description?: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  closeCallback?: Function
  buttonText?: string
  icon?: string
}

export interface DialogOptions {
  timeout?: number
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  private overlayRef!: OverlayRef
  overlay = inject(Overlay)

  open(modalOptions: ModalInfoOptions = {}, { timeout }: DialogOptions = {}) {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create()
    }

    if (this.overlayRef.hasAttached()) {
      return
    }
    const portal = new ComponentPortal(ConfirmDialogComponent)
    const componentRef = this.overlayRef.attach(portal)
    Object.keys(modalOptions).forEach((key) => {
      componentRef.setInput(key, modalOptions[key as keyof ModalInfoOptions])
    })

    componentRef.instance.handleClose.subscribe(() => {
      componentRef.destroy()
    })

    if (timeout) {
      setTimeout(() => {
        componentRef.instance.close()
      }, timeout)
    }
  }

  success(modalOptions: ModalInfoOptions, options?: DialogOptions) {
    this.open(
      {
        ...modalOptions,
        type: 'success',
      },
      options,
    )
  }
}
